// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '../Button';

// Styles
import './_text-image.scss';

// Utils
import { renderHtml } from '../../utils/renderHtml';
import getLocalizedPath from '../../utils/getLocalizedPath';
import Slider from 'react-slick';

const TextImage = ({
  button,
  centered,
  image,
  large,
  reversed,
  secondaryButton,
  subtitle,
  text,
  title,
  buttonsArtisan,
  galery,
}) => {
  let rowClasses = 'row';
  if (centered) rowClasses += ' align-items-center';

  let textClasses = 'text-image__text order-2 col-md-6 col-lg-5 col-xl-4';
  if (reversed) textClasses += ' offset-lg-1';
  else textClasses += ' order-md-1 offset-xl-1';

  let imageClasses = 'text-image__image order-1 col-md-6';
  if (!reversed) imageClasses += ' order-md-2 offset-lg-1';
  if (!large) imageClasses += ' col-xl-5';
  if (reversed && !large) imageClasses += ' offset-xl-1';

  const items = galery || [];

  return (
    <section className="text-image">
      <div className="container">
        <div className={rowClasses}>
          <div className={textClasses}>
            {!!subtitle && !!title && (
              <h2 className="text-image__title">
                <span className="text-image__subtitle">{subtitle}</span>
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </h2>
            )}
            {!subtitle && !!title && (
              <h2 className="text-image__title">
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </h2>
            )}
            <div className="text-image__text">{renderHtml(text)}</div>
            {!!button.text && (
              <Button
                text={button.text}
                className={button.className}
                href={button.href}
                light={button.light}
                onClick={button.onClick}
                outline={button.outline}
                target={button.target}
                to={button.to}
              >
                {button.text}
              </Button>
            )}
            {!!secondaryButton.text && (
              <Button
                text={secondaryButton.text}
                className={secondaryButton.className}
                href={secondaryButton.href}
                light={secondaryButton.light}
                onClick={secondaryButton.onClick}
                outline={secondaryButton.outline}
                target={secondaryButton.target}
                to={secondaryButton.to}
              >
                {secondaryButton.text}
              </Button>
            )}
            {!!buttonsArtisan && (
              <div className="row text-image__buttons-box">
                <div className="col-lg-6 col-md-6 col-6">
                  <a
                    className="btn btn-outline"
                    href={getLocalizedPath('restaurant')}
                  >
                    Restaurants
                  </a>
                </div>
                <div className="col-lg-6 col-md-6 col-6">
                  <a
                    className="btn btn-outline"
                    href={getLocalizedPath('artisan')}
                  >
                    Marché
                  </a>
                </div>
                <div className="col-lg-6 col-md-6 col-6">
                  <a
                    className="btn btn-outline"
                    href="/restaurants/cafe-marche-carrier"
                  >
                    Café
                  </a>
                </div>
                <div className="col-lg-6 col-md-6 col-6">
                  <a className="btn btn-outline" href={getLocalizedPath('bar')}>
                    Bar à cocktails
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className={imageClasses}>
            {!galery && !image.sizes.large && (
              <picture>
                <source
                  media="(min-width: 768px)"
                  srcSet={image.sizes.desktop}
                />
                <img src={image.sizes.mobile} alt={image.title} />
              </picture>
            )}
            {!galery && !!image.sizes.large && (
              <picture>
                <source media="(min-width: 768px)" srcSet={image.sizes.large} />
                <img src={image.sizes.large} alt={image.title} />
              </picture>
            )}
            {!!galery && (
              <Slider
                dots
                arrows={false}
                infinite
                speed={1000}
                autoplay
                slidesToShow={1}
                slidesToScroll={1}
                pauseOnHover
                swipeToSlide
                swipe
                touchThreshold={100}
                responsive={[
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 576,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ]}
              >
                {items.map(item => {
                  return <img src={item.url} />;
                })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

TextImage.propTypes = {
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    href: PropTypes.string,
    light: PropTypes.bool,
    onClick: PropTypes.func,
    outline: PropTypes.bool,
    target: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  centered: PropTypes.bool,
  image: PropTypes.shape({
    sizes: PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
  }).isRequired,
  large: PropTypes.bool,
  reversed: PropTypes.bool,
  secondaryButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    href: PropTypes.string,
    light: PropTypes.bool,
    onClick: PropTypes.func,
    outline: PropTypes.bool,
    target: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  subtitle: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  buttonsArtisan: PropTypes.bool,
};

TextImage.defaultProps = {
  button: {
    text: '',
    className: '',
    href: null,
    light: false,
    onClick: null,
    outline: true,
    target: null,
    to: null,
  },
  centered: true,
  large: false,
  reversed: false,
  secondaryButton: {
    text: '',
    className: '',
    href: null,
    light: false,
    onClick: null,
    outline: true,
    target: null,
    to: null,
  },
  subtitle: '',
  title: '',
  buttonsArtisan: false,
};

export default TextImage;
