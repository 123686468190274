// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { AppContext } from '../context';
import { Link } from 'react-router-dom';

// i18n
import i18n from '../i18n';

// Components
//import Button from '../components/Button';
import Card from '../components/Card';
import Carousel from '../components/Carousel';
import HeroVideo from '../components/HeroVideo';
import Layout from '../components/Layout';
import SectionTitle from '../components/SectionTitle';
import TextCenter from '../components/TextCenter';
import TextImage from '../components/TextImage';
import TextImageUnder from '../components/TextImageUnder';
import FeaturedCard from '../components/FeaturedCardList/FeaturedCard';

// Utils
import getLocalizedPath from '../utils/getLocalizedPath';
import { getHomepage } from '../utils/wordpressApi';
import { shuffle } from '../utils/tools';
import { bool } from 'prop-types';

class Home extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      chefs: {
        title: '',
        list: [],
      },
      concept: {
        image: {
          sizes: {
            landscapeWideLg: '',
            portraitSm: '',
          },
          title: '',
        },
        text: '',
        title: '',
        videoID: '',
      },
      promotions: {
        title: '',
        list: [],
      },
      drinkAndEat: {
        image: {
          sizes: {
            portraitLg: '',
            portraitSm: '',
          },
          title: '',
        },
        text: '',
        title: '',
      },
      events: {
        title: '',
        list: [],
      },
      hero: {
        image: {
          sizes: {
            desktop: '',
            mobile: '',
          },
          title: '',
        },
        text: '',
        title: '',
        videoID: '',
      },
      market: {
        images: [],
        text: '',
        title: '',
      },
      metatags: {},
      takeout: {
        image: {
          sizes: {
            portraitLg: '',
            portraitSm: '',
          },
          title: '',
        },
        text: '',
        title: '',
      },
      drinkAndEatGalery: [],
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getHomepage();

    if (this._isMounted && !!data && data.status === 'publish') {
      let { chefs } = data;

      const {
        chefsTitle,
        conceptImage,
        conceptText,
        conceptTitle,
        conceptVideo,
        drinkAndEatImage,
        drinkAndEatText,
        drinkAndEatTitle,
        events,
        eventsTitle,
        heroImage,
        heroVideo,
        heroText,
        heroTitle,
        marketImages,
        marketText,
        marketTitle,
        promotions,
        promotionsTitle,
        metatags,
        takeoutImage,
        takoutText,
        takoutTitle,
        drinkAndEatGalery,
      } = data;

      if (chefs && chefs.length > 0) {
        chefs = shuffle(chefs);
      }

      this.setState({
        chefs: {
          title: chefsTitle ? chefsTitle : '',
          list:
            chefs && chefs.length > 0
              ? chefs
                  .map(chef => {
                    const { image, name, restaurant, slug, status } = chef;

                    if (status === 'publish') {
                      return {
                        image: {
                          sizes: {
                            landscapeWideMd:
                              image &&
                              image.sizes &&
                              image.sizes.landscapeWideMd
                                ? image.sizes.landscapeWideMd
                                : '',
                            landscapeWideSm:
                              image &&
                              image.sizes &&
                              image.sizes.landscapeWideSm
                                ? image.sizes.landscapeWideSm
                                : '',
                          },
                          title:
                            image && image.title
                              ? image.title
                              : `${restaurant} - ${name}`,
                        },
                        link: `${getLocalizedPath('restaurant')}/${slug}`,
                        name,
                        restaurant,
                        slug,
                      };
                    }
                    return null;
                  })
                  .filter(chef => chef !== null)
              : [],
        },
        concept: {
          image: {
            sizes: {
              landscapeWideLg:
                conceptImage &&
                conceptImage.sizes &&
                conceptImage.sizes.landscapeWideLg
                  ? conceptImage.sizes.landscapeWideLg
                  : '',
              portraitSm:
                conceptImage &&
                conceptImage.sizes &&
                conceptImage.sizes.portraitSm
                  ? conceptImage.sizes.portraitSm
                  : '',
            },
            title:
              conceptImage && conceptImage.title
                ? conceptImage.title
                : conceptTitle,
          },
          text: conceptText ? conceptText : '',
          title: conceptTitle ? conceptTitle : '',
          videoID: conceptVideo ? conceptVideo : '',
        },
        drinkAndEat: {
          image: {
            sizes: {
              portraitLg:
                drinkAndEatImage &&
                drinkAndEatImage.sizes &&
                drinkAndEatImage.sizes.portraitLg
                  ? drinkAndEatImage.sizes.portraitLg
                  : '',
              portraitSm:
                drinkAndEatImage &&
                drinkAndEatImage.sizes &&
                drinkAndEatImage.sizes.portraitSm
                  ? drinkAndEatImage.sizes.portraitSm
                  : '',
            },
            title:
              drinkAndEatImage && drinkAndEatImage.title
                ? drinkAndEatImage.title
                : drinkAndEatTitle,
          },
          text: drinkAndEatText ? drinkAndEatText : '',
          title: drinkAndEatTitle ? drinkAndEatTitle : '',
          galery: drinkAndEatGalery,
        },
        events: {
          title: eventsTitle ? eventsTitle : '',
          list:
            events && events.length > 0
              ? events
                  .map(event => {
                    const {
                      beginDate,
                      endDate,
                      beginTime,
                      id,
                      image,
                      slug,
                      status,
                      title,
                      show_index_artisan,
                    } = event;

                    if(String(show_index_artisan).includes("index")){
                      if (status === 'publish') {
                        return {
                          beginDate,
                          endDate,
                          beginTime,
                          image: {
                            sizes: {
                              desktop:
                                image && image.sizes && image.sizes.landscapeLg
                                  ? image.sizes.landscapeLg
                                  : '',
                              mobile:
                                image && image.sizes && image.sizes.landscapeSm
                                  ? image.sizes.landscapeSm
                                  : '',
                            },
                            title: image && image.title ? image.title : title,
                          },
                          id,
                          link: `${getLocalizedPath('event')}/${slug}`,
                          title,
                          slug,
                          show_index_artisan,
                        };
                      }
                    }
                    return null;
                  })
                  .filter(event => event !== null)
              : [],
        },
        hero: {
          image: {
            sizes: {
              desktop:
                heroImage && heroImage.sizes && heroImage.sizes.xl
                  ? heroImage.sizes.xl
                  : '',
              mobile:
                heroImage && heroImage.sizes && heroImage.sizes.landscapeSm
                  ? heroImage.sizes.landscapeSm
                  : '',
            },
            title: heroImage && heroImage.title ? heroImage.title : heroTitle,
          },
          text: heroText ? heroText : '',
          title: heroTitle ? heroTitle : '',
          videoID: heroVideo ? heroVideo : '',
        },
        market: {
          images:
            marketImages && marketImages.length > 0
              ? marketImages.map(image => ({
                  id: image.id,
                  sizes: {
                    landscapeWideLg:
                      image.sizes && image.sizes.landscapeWideLg
                        ? image.sizes.landscapeWideLg
                        : '',
                    squareSm:
                      image.sizes && image.sizes.squareSm
                        ? image.sizes.squareSm
                        : '',
                  },
                  title: image.title ? image.title : marketTitle,
                }))
              : [],
          text: marketText ? marketText : '',
          title: marketTitle ? marketTitle : '',
        },
        promotions: {
          title: promotionsTitle ? promotionsTitle : '',
          list:
            promotions && promotions.length > 0
              ? promotions
                  .map(promotion => {
                    const { image, slug, title, show_index_artisan } = promotion;

                    if(String(show_index_artisan).includes("index")){
                      return {
                        image: {
                          sizes: {
                            landscapeMd:
                              image && image.sizes && image.sizes.landscapeMd
                                ? image.sizes.landscapeMd
                                : '',
                            landscapeSm:
                              image && image.sizes && image.sizes.landscapeSm
                                ? image.sizes.landscapeSm
                                : '',
                          },
                          title: image && image.title ? image.title : title,
                        },
                        pathname: getLocalizedPath('promotion'),
                        title,
                        slug,
                        dontShow: "false",
                      };
                    }
                    else{
                      return null;
                    }
                  })
                  .filter(promotion => promotion !== null)
              : [],
        },
        metatags,
        takeout: {
          image: {
            sizes: {
              portraitLg:
                takeoutImage &&
                takeoutImage.sizes &&
                takeoutImage.sizes.portraitLg
                  ? takeoutImage.sizes.portraitLg
                  : '',
              portraitSm:
                takeoutImage &&
                takeoutImage.sizes &&
                takeoutImage.sizes.portraitSm
                  ? takeoutImage.sizes.portraitSm
                  : '',
            },
            title:
              takeoutImage && takeoutImage.title
                ? takeoutImage.title
                : takoutTitle,
          },
          text: takoutText ? takoutText : '',
          title: takoutTitle ? takoutTitle : '',
        },
      });
    }

    // Set loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      chefs,
      concept,
      drinkAndEat,
      events,
      hero,
      market,
      promotions,
      metatags,
      takeout,
    } = this.state;

    const {
      options: {
        links: { book: bookLink, gift_card: giftLink, order: orderLink, },
      },
    } = this.context;

    const config = {
      headers: { Authorization: `Bearer token` },
    };

    const data = [];

    const result = { ...config, body: data };

    const sliderCountOffers= (promotions.list.length > 2 ? 3: promotions.list.length);

    const sliderCountEvents= (events.list.length > 2 ? 3: events.list.length);

    return (
      <Layout className="homepage" metatags={metatags}>
        <HeroVideo
          image={hero.image}
          text={hero.text}
          title={hero.title}
          buttonlink1={giftLink}
          buttonlink2={bookLink}
          buttonlink3={orderLink}
          buttontext1="Promotions"
          buttontext2="Réservez"
          buttontext3="Commandez"
          videoID={hero.videoID}
          arrow={true}
        />

        <TextImageUnder
          image={{
            sizes: {
              desktop: concept.image.sizes.landscapeWideLg,
              mobile: concept.image.sizes.portraitSm,
            },
            title: concept.image.title,
          }}
          presentation="introduction"
          surtitle={i18n.t('concept')}
          title={concept.title}
          videoID={concept.videoID}
        />

        <>
          <SectionTitle
            button={{
              text: i18n.t('see_all_events'),
              to: getLocalizedPath('event'),
            }}
            full={true}
            surtitle={i18n.t('events').title}
            title={events.title}
          />
          <section>
            <div className="container">
              <div className="row">
                <Slider
                  dots
                  infinite
                  arrows={false}
                  speed={1000}
                  autoplay
                  slidesToShow={sliderCountEvents}
                  pauseOnHover
                  swipeToSlide
                  swipe
                  touchThreshold={100}
                  responsive={[
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                      },
                    },
                    {
                      breakpoint: 576,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                  ]}
                >
                  {events.list.map(item => (
                    <FeaturedCard
                      beginDate={item.beginDate}
                      endDate={item.endDate}
                      image={item.image}
                      key={item.id}
                      link={item.link}
                      slug={item.slug}
                      title={item.title}
                      beginTime={item.beginTime}
                      showIndexArtisan={item.show_index_artisan}
                      currentIndexArtisan="index"
                      overwriteClasse={"featured-card full col-12 card-list__item"}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          </section>
        </>

        <div className="background-white">

          <div className="container spacer--bottom">
            <hr />
          </div>

          <TextImage
            buttonsArtisan={true}
            image={{
              sizes: {
                desktop: drinkAndEat.image.sizes.portraitLg,
                mobile: drinkAndEat.image.sizes.portraitSm,
              },
              title: takeout.image.title,
            }}
            large
            reversed
            subtitle={i18n.t('menu').restaurants}
            text={drinkAndEat.text}
            title={drinkAndEat.title}
            galery={drinkAndEat.galery}
          />

          {chefs.list.length > 0 && (
            <section className="chefs">
              <div className="chefs__wrapper">
                <div className="container">
                  <SectionTitle
                    centered
                    light
                    surtitle={i18n.t('craftsmen').our_craftsmen}
                    title={chefs.title}
                  />

                  <div className="card-list row">
                    <Slider
                      dots
                      arrows={false}
                      infinite
                      speed={1000}
                      autoplay
                      slidesToShow={3}
                      slidesToScroll={3}
                      pauseOnHover
                      swipeToSlide
                      swipe
                      touchThreshold={100}
                      responsive={[
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                          },
                        },
                        {
                          breakpoint: 576,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          },
                        },
                      ]}
                    >
                      {chefs.list.map(
                        ({ image, link, name, restaurant, slug }) => {
                          return (
                            <Card
                              key={slug}
                              theme="dark"
                              title={name}
                              image={{
                                sizes: {
                                  medium: image.sizes.landscapeWideMd,
                                  small: image.sizes.landscapeWideSm,
                                },
                                title: image.title,
                              }}
                              slug={slug}
                              subtitle={`${i18n.t('chef').title} ${restaurant}`}
                              to={{
                                pathname: link,
                                state: { anchor: 'chef-anchor' },
                              }}
                              hover={name}
                            />
                          );
                        }
                      )}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </Layout>
    );
  }
}

Home.contextType = AppContext;

export default withTranslation()(Home);
