// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '../Button';
import Input from '../Input';

// i18n
import i18n from '../../i18n';

// Utilities
import validateField from '../../utils/validateField';

// Styles
import './_newsletter-form.scss';

class NewsletterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        email: {
          value: '',
          type: 'email',
          label: i18n.t('newsletter').email,
          id: 'newsletter_email',
          name: 'email',
          rules: ['required', 'email'],
          error: false,
        },
        /* events: {
          value: false,
          type: 'checkbox',
          label: i18n.t('newsletter').events,
          id: 'events',
          name: 'events',
          rules: [],
          error: false,
        },
        contests: {
          value: false,
          type: 'checkbox',
          label: i18n.t('newsletter').contests,
          id: 'contests',
          name: 'contests',
          rules: [],
          error: false,
        },
        offers: {
          value: false,
          type: 'checkbox',
          label: i18n.t('newsletter').offers,
          id: 'offers',
          name: 'offers',
          rules: [],
          error: false,
        },
        news: {
          value: false,
          type: 'checkbox',
          label: i18n.t('newsletter').news,
          id: 'news',
          name: 'news',
          rules: [],
          error: false,
        }, */
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value, checked } = e.target;

    const {
      fields: {
        [name]: { type },
      },
    } = this.state;

    let newValue = value;

    if (type === 'checkbox') {
      newValue = checked;
    }

    this.setState(prevState => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: {
          ...prevState.fields[name],
          value: newValue,
          error: false,
        },
      },
    }));
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const { fields } = this.state;
    const errors = [];

    /**
     * Validate fields defined in state
     */
    Object.keys(fields).map(name => {
      const field = fields[name];

      if (!validateField(field.value, field.rules)) {
        errors.push(name);

        this.setState(prevState => ({
          ...prevState,
          fields: {
            ...prevState.fields,
            [name]: {
              ...prevState.fields[name],
              error: true,
            },
          },
        }));
      }

      return true;
    });

    if (errors.length === 0) {
      const { onValidated } = this.props;

      const {
        email: { value: email },
      } = fields;

      /* let {
        events: { value: events },
        contests: { value: contests },
        offers: { value: offers },
        news: { value: news },
      } = fields;

      if (!events && !contests && !offers && !news) {
        events = true;
        contests = true;
        offers = true;
        news = true;
      } */

      onValidated({
        EMAIL: email,
        /* EVENTS: events ? 1 : 0,
        CONTESTS: contests ? 1 : 0,
        OFFERS: offers ? 1 : 0,
        NEWS: news ? 1 : 0, */
      });
    }
  }

  render() {
    const { message, status } = this.props;

    const {
      // fields: { email, events, contests, offers, news },
      fields: { email },
    } = this.state;

    return (
      <div className="row">
        <form className="newsletter-form__form col-md-10 col-lg-8 col-xl-6 mx-auto">
          {/* <div className="checkbox-list">
            <div key={events.id} className="checkbox-list__item">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id={events.id}
                  name={events.name}
                  onChange={this.handleChange}
                  type={events.type}
                  value={events.value}
                />
                <label className="custom-control-label" htmlFor={events.id}>
                  <span>{events.label}</span>
                </label>
              </div>
            </div>

            <div key={contests.id} className="checkbox-list__item">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id={contests.id}
                  name={contests.name}
                  onChange={this.handleChange}
                  type={contests.type}
                  value={contests.value}
                />
                <label className="custom-control-label" htmlFor={contests.id}>
                  <span>{contests.label}</span>
                </label>
              </div>
            </div>

            <div key={offers.id} className="checkbox-list__item">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id={offers.id}
                  name={offers.name}
                  onChange={this.handleChange}
                  type={offers.type}
                  value={offers.value}
                />
                <label className="custom-control-label" htmlFor={offers.id}>
                  <span>{offers.label}</span>
                </label>
              </div>
            </div>

            <div key={news.id} className="checkbox-list__item">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id={news.id}
                  name={news.name}
                  onChange={this.handleChange}
                  type={news.type}
                  value={news.value}
                />
                <label className="custom-control-label" htmlFor={news.id}>
                  <span>{news.label}</span>
                </label>
              </div>
            </div>
          </div> */}
          <div className="form-input">
            <Input
              error={email.error}
              className="newsletter-input"
              type={email.type}
              label={email.label}
              id={email.id}
              name={email.name}
              value={email.value}
              handleChange={this.handleChange}
            />
            <Button
              onClick={this.handleFormSubmit}
              type="submit"
              className="btn-outline"
            >
              {i18n.t('newsletter').subscribe}
            </Button>
          </div>
          {status === 'sending' && (
            <p className="message message--sending">
              {i18n.t('newsletter').sending}...
            </p>
          )}
          {status === 'error' && (
            <p
              className="message message--error"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === 'success' && (
            <p
              className="message message--success"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </form>
      </div>
    );
  }
}

NewsletterForm.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  onValidated: PropTypes.func.isRequired,
};

NewsletterForm.defaultProps = {
  status: '',
  message: '',
};

export default NewsletterForm;
