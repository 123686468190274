// Vendors
import React from 'react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';

import DownCarretSVG from '../../assets/svg/down-carret';

// Styles
import './_hero-video.scss';

const HeroVideo = ({
  button,
  image,
  text,
  title,
  arrow,
  videoID,
  buttonlink1,
  buttonlink2,
  buttonlink3,
  buttontext1,
  buttontext2,
  buttontext3,
  text_top,
}) => {
  let styles = {};

  if (image.sizes.desktop) {
    styles = { backgroundImage: `url(${image.sizes.desktop})` };
  }

  return (
    <section className="hero-video" id="video">
      {!!text_top && <div className="top-text">{text_top}</div>}
      <div className="container">
        <div className="hero-video__content">
          <h1>{title}</h1>
          <p>{text}</p>
        </div>
      </div>
      <div className="hero-video__wrapper" style={styles}>
        {!!videoID && (
          <iframe
            src={`https://player.vimeo.com/video/${videoID}?background=1&autopause=0&dnt=true`}
            width="1920"
            height="1080"
            allow="autoplay; fullscreen"
            allowFullScreen
            sandbox="allow-scripts allow-same-origin"
            title="District Gourmet - Vidéo d'entête"
          />
        )}
      </div>
      {!!buttonlink1 && !!buttonlink2 && !!buttonlink3 && (
        <div className="hero-video__bottom-boxs">
          <div className="container">
            <a href={buttonlink1}>{Parser(buttontext1)}</a>
            <a href={buttonlink2}>{Parser(buttontext2)}</a>
            <a href={buttonlink3}>{Parser(buttontext3)}</a>
          </div>
        </div>
      )}
      {!!arrow && (
        <div className="text-center hero-video__arrow-box">
          <a href="#first-section">
            <DownCarretSVG />
          </a>
        </div>
      )}
    </section>
  );
};

HeroVideo.propTypes = {
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    href: PropTypes.string,
    light: PropTypes.bool,
    onClick: PropTypes.func,
    outline: PropTypes.bool,
    target: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  image: PropTypes.shape({
    sizes: PropTypes.shape({
      desktop: PropTypes.string,
    }),
  }),
  text: PropTypes.string,
  title: PropTypes.string,
  videoID: PropTypes.string,
  bookLink: PropTypes.string,
  giftLink: PropTypes.string,
};

HeroVideo.defaultProps = {
  button: {
    text: '',
    className: '',
    href: null,
    light: false,
    onClick: null,
    outline: true,
    target: null,
    to: null,
  },
  image: {
    sizes: {
      desktop: '',
    },
  },
  text: '',
  title: '',
  bookLink: '',
  giftLink: '',
};

export default HeroVideo;
