// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '../Button';

// Styles
import './_text-image-under.scss';

const TextImageUnder = ({
  button,
  image,
  presentation,
  surtitle,
  text,
  title,
  videoID,
}) => {
  console.log(videoID);
  return (
    <section className={`text-image-under text-image-under--${presentation}`}>
      <div className="text-image-under__grid" />
      <div className="container">
        <div className="row">
          <div className="text-image-under__content col-11 col-md-10 col-lg-8 col-xl-7 mx-auto">
            <h2 className="text-image-under__title">
              {!!surtitle && (
                <span className="text-image-under__surtitle">{surtitle}</span>
              )}
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </h2>
            {!!text && (
              <div
                className="text-image-under__text"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
            {!!button.text && (
              <Button
                text={button.text}
                className={button.className}
                href={button.href}
                onClick={button.onClick}
                outline={button.outline}
                target={button.target}
                to={button.to}
              >
                {button.text}
              </Button>
            )}
          </div>
          <div className="text-image-under__image col-12 mx-auto col-xl-10">
            {!videoID && (
              <picture>
                <source
                  media="(min-width: 768px)"
                  srcSet={image.sizes.desktop}
                />
                <img src={image.sizes.mobile} alt={image.title} />
              </picture>
            )}

            {!!videoID && (
              <div className="text-image-under__video">
                <iframe
                  src={`https://player.vimeo.com/video/${videoID}?dnt=true`}
                  allow="fullscreen"
                  allowFullScreen
                  sandbox="allow-scripts allow-same-origin"
                  title="District Gourmet - Notre concept"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

TextImageUnder.propTypes = {
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    outline: PropTypes.bool,
    target: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  image: PropTypes.shape({
    sizes: PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
  }),
  presentation: PropTypes.string,
  surtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  videoID: PropTypes.string,
};

TextImageUnder.defaultProps = {
  button: {
    text: '',
    className: '',
    href: null,
    onClick: null,
    outline: false,
    target: null,
    to: null,
  },
  image: {
    sizes: {
      desktop: '',
      mobile: '',
    },
    title: '',
  },
  presentation: 'content',
  surtitle: '',
  text: '',
  videoID: '',
};

export default TextImageUnder;
