// Vendors
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { AppContext } from '../context';

// i18n
import i18n from '../i18n';

// Components
import Button from '../components/Button';
import Card from '../components/Card';
import Carousel from '../components/Carousel';
import Dropdown from '../components/Dropdown';
import Layout from '../components/Layout';
import HeroVideo from '../components/HeroVideo';
import SectionTitle from '../components/SectionTitle';
import TextImage from '../components/TextImage';
import FeaturedCard from '../components/FeaturedCardList/FeaturedCard';

// Utils
import { getArtisans } from '../utils/wordpressApi';
import filters from '../utils/filters';
import getLocalizedPath from '../utils/getLocalizedPath';

class Artisan extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let {
      location: { pathname },
    } = this.props;

    pathname = pathname.replace(/\/\s*$/, '');

    this.state = {
      artisans: [],
      categories: [],
      filtered: [],
      introduction: {
        description: '',
        image: {
          sizes: {
            desktop: '',
            mobile: '',
          },
          title: '',
        },
      },
      promotions: {
        title: '',
        list: [],
      },
      events: {
        title: '',
        list: [],
      },
      metatags: {},
      pathname,
      shops: {
        images: [],
        title: '',
      },
    };

    this.filterList = this.filterList.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;

    const { setEvent, unsetEvent } = this.context;

    // Set loading
    setEvent('loading');

    // Fetch Wordpress Data
    const data = await getArtisans();

    if (this._isMounted && !!data) {
      const {
        artisans,
        metatags,
        settings: {
          description,
          image,
          title,
          shops,
          url_video,
          titre,
          sous_titre,
          text_top,
          url_bouton_1,
          url_bouton_2,
          url_bouton_3,
          texte_bouton_1,
          texte_bouton_2,
          texte_bouton_3,
          promotionsTitle,
          eventsTitle,
          artisans_title_sub,
        },
        events,
        promotions,
      } = data;
      this.setState(prevState => ({
        ...prevState,
        artisans,
        introduction: {
          description,
          image,
          title,
        },
        metatags,
        shops,
        url_video,
        titre,
        sous_titre,
        text_top,
        url_bouton_1,
        url_bouton_2,
        url_bouton_3,
        texte_bouton_1,
        texte_bouton_2,
        texte_bouton_3,
        promotionsTitle,
        eventsTitle,
        artisans_title_sub,
        events: {
          list:
            events && events.length > 0
              ? events
                  .map(event => {
                    const {
                      beginDate,
                      endDate,
                      beginTime,
                      id,
                      image,
                      slug,
                      status,
                      title,
                      show_index_artisan,
                    } = event;

                    if (status === 'publish') {
                      if (String(show_index_artisan).includes('marche')) {
                        return {
                          beginDate,
                          endDate,
                          beginTime,
                          image: {
                            sizes: {
                              desktop:
                                image && image.sizes && image.sizes.landscapeLg
                                  ? image.sizes.landscapeLg
                                  : '',
                              mobile:
                                image && image.sizes && image.sizes.landscapeSm
                                  ? image.sizes.landscapeSm
                                  : '',
                            },
                            title: image && image.title ? image.title : title,
                          },
                          id,
                          link: `${getLocalizedPath('event')}/${slug}`,
                          title,
                          slug,
                          show_index_artisan,
                          dontShow: 'false',
                        };
                      }
                    }
                    return null;
                  })
                  .filter(event => event !== null)
              : [],
        },
        promotions: {
          list:
            promotions && promotions.length > 0
              ? promotions
                  .map(promotion => {
                    const {
                      image,
                      slug,
                      title,
                      show_index_artisan,
                    } = promotion;
                    if (String(show_index_artisan).includes('marche')) {
                      return {
                        image: {
                          sizes: {
                            landscapeMd:
                              image && image.sizes && image.sizes.landscapeMd
                                ? image.sizes.landscapeMd
                                : '',
                            landscapeSm:
                              image && image.sizes && image.sizes.landscapeSm
                                ? image.sizes.landscapeSm
                                : '',
                          },
                          title: image && image.title ? image.title : title,
                        },
                        pathname: getLocalizedPath('promotion'),
                        title,
                        slug,
                        dontShow: 'false',
                      };
                    }

                    return null;
                  })
                  .filter(promotion => promotion !== null)
              : [],
        },
      }));

      /**
       * Get distinct artisan categories
       */
      const categories = artisans
        .filter(
          (artisan, index, self) =>
            index ===
            self.findIndex(t => t.category.slug === artisan.category.slug)
        )
        .map(artisan => {
          return artisan.category;
        });

      this.setState({
        categories,
        filtered: this.filterList(null, null),
      });
    }

    // Unset loading
    unsetEvent('loading');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Filter list
   */
  filterList(type, slug) {
    const { artisans } = this.state;
    let filtered = [];

    if (!type || !slug) {
      filtered = [...artisans];
    } else {
      filtered = filters('category', slug, artisans);
    }

    this.setState({
      filtered,
    });

    return filtered;
  }

  render() {
    const {
      categories,
      filtered,
      introduction,
      metatags,
      pathname,
      shops,
      url_video,
      titre,
      sous_titre,
      text_top,
      url_bouton_1,
      url_bouton_2,
      url_bouton_3,
      texte_bouton_1,
      texte_bouton_2,
      texte_bouton_3,
      promotions,
      events,
      promotionsTitle,
      eventsTitle,
      artisans_title_sub,
    } = this.state;

    // const {
    //   options: {
    //     links: { grocery: groceryLink },
    //   },
    // } = this.context;

    console.log(promotions.list.length);

    const sliderCountOffers =
      promotions.list.length > 2 ? 3 : promotions.list.length;

    const sliderCountEvents = events.list.length > 2 ? 3 : events.list.length;

    return (
      <Layout className="artisan-page" metatags={metatags}>
        <HeroVideo
          text={sous_titre}
          title={titre}
          videoLink={url_video}
          // buttonlink1={url_bouton_1}
          // buttonlink2={url_bouton_2}
          // buttonlink3={url_bouton_3}
          // buttontext1={texte_bouton_1}
          // buttontext2={texte_bouton_2}
          // buttontext3={texte_bouton_3}
          text_top={text_top}
          arrow
        />
        {/* {promotions.list.length > 0 && (
          <>
            <div id="first-section" className="spacer--top--large">
              <div id="offres">
                <SectionTitle
                  surtitle={i18n.t('offers').title}
                  title={promotionsTitle}
                  full={true}
                />
              </div>
            </div>
            <div className="homepage no-padder">
              <section className="home-offers">
                <div className="container">
                  <div className="home-offers__list row">
                    <Slider
                      dots
                      arrows={false}
                      speed={1000}
                      autoplay
                      slidesToShow={sliderCountOffers}
                      slidesToScroll={3}
                      pauseOnHover
                      swipeToSlide
                      swipe
                      touchThreshold={100}
                      responsive={[
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                          },
                        },
                        {
                          breakpoint: 576,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          },
                        },
                      ]}
                    >
                      {promotions.list.map(promotion => (
                        promotion.dontShow === "false" ? (
                          <Card
                          key={promotion.slug}
                          title=""
                          caption=""
                          image={{
                            sizes: {
                              medium: promotion.image.sizes.landscapeMd,
                              small: promotion.image.sizes.landscapeSm,
                            },
                            title: promotion.image.title,
                          }}
                          subtitle=""
                          slug={promotion.slug}
                          to={`${promotion.pathname}/${promotion.slug}`}
                          hover="Découvrir"
                          overwriteClasse="card-list__item col-12 item__light"
                          />
                        ) : (
                          null
                        )
                      ))}
                    </Slider>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
        <br/><br/>
        <>
          <div id="evenements">
            <SectionTitle
              button={{
                text: i18n.t('see_all_events'),
                to: getLocalizedPath('event'),
              }}
              full={true}
              surtitle={i18n.t('events').title}
              title={eventsTitle}
            />
          </div>
          <section>
            <div className="container">
              <div className="row">
                <Slider
                  dots
                  infinite
                  arrows={false}
                  speed={1000}
                  autoplay
                  slidesToShow={sliderCountEvents}
                  pauseOnHover
                  swipeToSlide
                  swipe
                  touchThreshold={100}
                  responsive={[
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                      },
                    },
                    {
                      breakpoint: 576,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                  ]}
                >
                {events.list.map(item => (
                  <FeaturedCard
                    beginDate={item.beginDate}
                    endDate={item.endDate}
                    image={item.image}
                    key={item.id}
                    link={item.link}
                    slug={item.slug}
                    title={item.title}
                    beginTime={item.beginTime}
                    overwriteClasse={"featured-card full col-12 card-list__item"}
                  />
                ))}
                </Slider>
              </div>
            </div>
          </section>
        </> */}

        <div id="produits">
          <TextImage
            image={{
              sizes: {
                desktop: introduction.image.sizes.portraitLg,
                mobile: introduction.image.sizes.landscapeWideSm,
              },
              title: introduction.image.title,
            }}
            reversed
            text={introduction.description}
            title={introduction.title}
            subtitle={artisans_title_sub}
          />
        </div>

        <section className="artisan-list">
          <div className="container">
            {/*
            <div className="card-filters row">
              <div className="card-filters__category col-10 offset-1 col-md-8 offset-md-2 col-lg-4 offset-lg-1 col-xl-3">
                <Dropdown
                  id="categoryDropdown"
                  onClick={this.filterList}
                  options={categories}
                  placeholder={i18n.t('menu').categories}
                  type="category"
                />
              </div>
            </div>
            */}
            <div className="card-list row">
              {filtered.map(({ title, caption, category, image, slug }) => {
                return (
                  <Card
                    key={slug}
                    title={title}
                    caption={caption}
                    image={{
                      sizes: {
                        medium: image.sizes.landscapeWideMd,
                        small: image.sizes.landscapeWideSm,
                      },
                      title: image.title,
                    }}
                    subtitle={category.name}
                    slug={slug}
                    to={`${pathname}/${slug}`}
                    hover={i18n.t('see_more')}
                  />
                );
              })}
            </div>
          </div>
        </section>

        {/*
					<SectionTitle
	          surtitle={i18n.t('shops').our_shops}
	          title={shops.title}
	          centered={false}
	        />
					<section className="shops">
	          <div className="container">
	            <div className="shops__item">PLAN</div>
	          </div>
	        </section>
				*/}

        {!!shops.images.length && (
          <section className="shops__slider">
            <Carousel
              items={shops.images.map(image => {
                return {
                  title: image.title,
                  sizes: {
                    large: image.sizes.squareSm,
                    xl: image.sizes.landscapeWideLg,
                  },
                };
              })}
            />
          </section>
        )}
      </Layout>
    );
  }
}

Artisan.contextType = AppContext;

export default withTranslation()(Artisan);
